.Programs{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 2rem;
}

.programs-header{
    display: flex;
    gap: 5rem;
    font-weight: bold;
    font-size: 3rem;
    justify-content: center;
    color: rgb(254, 4, 204);
    text-transform: uppercase;
    font-style: italic;
}

.program-categories{
    display: flex;
    gap: 1rem;
}

.category{
    display: flex;
    flex-direction: column;
    background-color: rgba(49, 56, 29, 0.896);
    padding: 2rem;
    gap: 1rem;
    color: white;
    justify-content: space-between;
}

.category>:nth-child(1){
    width: 2rem;
    filter: invert(100%);
    height: 2rem;
}

.category>:nth-child(2){
   font-size: 1rem;
   font-weight: bold;
}

.category>:nth-child(3){
    font-size: 0.9rem;
    line-height: 25px;
 }

 .join-now{
    display: flex;
    gap: 2rem;
    align-items: center;
 }

 .join-now>img{
    width: 2rem;
    filter: invert(100%);
 }

 .category:hover{
    cursor: pointer;
    background: rgb(139,2,151);
    background: linear-gradient(90deg, rgba(139,2,151,0.9977240896358543) 0%, rgba(203,40,201,1) 100%);
 }


 @media screen and (max-width:768px){
    .programs-header{
        flex-direction: column;
        gap: 1rem;
        font-size: x-large;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
    }
    .program-categories{
        flex-direction: column;
    }
 }