.App{
  display: flex;
  flex-direction: column;
  gap: 6rem;
  overflow: hidden;
  mix-blend-mode: overlay;
}

::-webkit-scrollbar{
  display: none;
}

.stroke-text{
  color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
}

.stroke-text-p{
  color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgb(254, 4, 204);
}

.stroke-text-g{
  color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(49, 56, 29, 0.896);
}

.btn{
  background-color: rgba(49, 56, 29, 0.896);
  padding: 0.5rem;
  font-weight: bold;
  border: 4px solid transparent;
  transition: 300ms all;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
}

.btn:hover{
  cursor: pointer;
}

.blur{
  background: rgb(248, 128, 224);
  position: absolute;
  border-radius: 50%;
  filter: blur(400px);
  z-index: -9;
}

.blur-w{
  background: rgb(212, 210, 210);
  position: absolute;
  border-radius: 50%;
  filter: blur(150px);
  z-index: 1;
}