.header{
  display: flex;
  justify-content: space-between;
}

.logo{
  width: 10rem;
  height: 5rem;
}

.header-menu{
  list-style: none;
  display: flex;
  gap: 2rem;
  color: white;
  font-weight: 700;
}

.header-menu>li{
  cursor: pointer;
}

.header-menu>li:hover{
  color: rgb(253, 5, 220);
  cursor: pointer;
}

@media screen and (max-width:768px){
  .header>:nth-child(2){
      position: fixed;
      right: 2rem;
      z-index: 99;
  }
  .header-menu{
      flex-direction: column;
      background-color: rgb(67, 67, 67);
      padding: 2rem;
  }
  .logo{
      width: 6rem;
      height: 3rem;
  }
}