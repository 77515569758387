.plans-container{
    margin-top: -4rem;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    position: relative;
}

.plans{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
}

.plan{
    display: flex;
    flex-direction: column;
    background-color: rgba(49, 56, 29, 0.896);
    color: white;
    gap: 2rem;
    padding: 1.5rem;
    width: 15rem;
}

.plan:nth-child(2){
    background: rgb(234,113,237);
    background: linear-gradient(90deg, rgba(234,113,237,1) 0%, rgba(203,40,201,1) 100%);
    transform: scale(1.1);
    font-weight: bold;
    font-size: 1rem;
    height: 31rem;
}

.plan>img{
    filter: invert(100%);
    width: 2rem;
    height: 2rem;
}

.plan>:nth-child(2){
    font-weight: bold;
    font-size: 1rem;
}

.plan>:nth-child(3){
    font-weight: bold;
    font-size: 3rem;
}

.plan>:nth-child(5){
    font-size: 0.8rem;
}

.features{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.feature{
    display: flex;
    align-items: center;
    gap: 1rem;
}

.feature>img{
    width: 1rem;
}

.plans>:nth-child(2)>img{
    filter: invert(0);
    width: 4rem;
    height: 4rem;
}

.plan-blur-1{
    width: 32rem;
    height: 23rem;
    top: 6rem;
    left: 6rem;
}

.plan-blur-2{
    width: 32rem;
    height: 23rem;
    top: 10rem;
    right: 7rem;
}

@media screen and (max-width:768px){
    .plans{
        flex-direction: column;
    }
    .plans>:nth-child(2){
        transform: none;
    }
}