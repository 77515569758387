.footer {
  background-color: rgba(35, 41, 21, 0.896);
  color: #fff;
  padding: 20px 0;
  text-align: center;
  position: relative; /* Add this to establish a positioning context */
}

.footer p {
  margin-bottom: 10px;
  font-size: 1.2rem;
}

.footer nav ul {
  list-style-type: none;
  padding: 0;
}

.footer nav ul li {
  display: inline-block;
  margin-right: 10px;
}

.footer nav ul li a {
  color: #fff;
  text-decoration: none;
}

.footer nav ul li a:hover {
  color: rgb(254, 4, 204);
}

