body {
    overflow-x: hidden; /* Prevent horizontal scrolling */
  }
  
  .hero {
    display: flex;
    justify-content: space-between;
    background-image: url('../assets/bg.png');
    background-size: cover;
    overflow-x: hidden; /* Prevent horizontal scrolling */
  }
  
  .left-h {
    padding: 2rem;
    padding-top: 1.5rem;
    flex: 3;
    display: flex;
    gap: 2rem;
    flex-direction: column;
  }
  
  .right-h {
    flex: 1;
    position: relative;
  }
  
  .the-best-ad {
    margin-top: 4rem;
    background-color: rgba(119, 130, 89, 0.896);
    border-radius: 4rem;
    border-color: 1px solid rgb(140, 136, 136);
    width: fit-content;
    padding: 20px 13px;
    text-transform: uppercase;
    color: white;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  
  .the-best-ad > span {
    z-index: 2;
  }
  
  .the-best-ad > div {
    position: absolute;
    background-color: rgba(49, 56, 29, 0.896);
    width: 5.4rem;
    height: 80%;
    left: 8px;
    border-radius: 3rem;
    z-index: 1;
  }
  
  .hero-text {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    text-transform: uppercase;
    font-size: 4.5rem;
    font-weight: bold;
    color: white;
    text-overflow: inherit;
  }
  
  .hero-text > div:nth-of-type(3) {
    font-size: 1rem;
    font-weight: 200;
    text-transform: none;
    letter-spacing: 1px;
    width: 80%;
  }
  
  .figures {
    display: flex;
    gap: 2rem;
  }
  
  .figures > div {
    display: flex;
    flex-direction: column;
  }
  
  .figures > div > span:nth-of-type(1) {
    color: white;
    font-size: 2rem;
  }
  
  .figures > div > span:nth-of-type(2) {
    color: rgb(208, 205, 205);
    text-transform: uppercase;
  }
  
  .hero-buttons {
    display: flex;
    gap: 1rem;
    font-weight: normal;
    margin-top: 2rem;
  }
  
  .hero-buttons > :nth-child(1) {
    color: rgba(49, 56, 29, 0.896);
    background-color: white;
    width: 8rem;
  }
  
  .hero-buttons > :nth-child(1):hover {
    color: white;
    background-color: transparent;
    border: 4px solid white;
    width: 8rem;
  }
  
  .hero-buttons > :nth-child(2) {
    color: white;
    background-color: transparent;
    width: 8rem;
    border: 2px solid white;
  }
  
  .hero-buttons > :nth-child(2):hover {
    color: rgba(49, 56, 29, 0.896);
    background-color: white;
    width: 8rem;
    border: 2px solid white;
  }
  
  .right-h > .btn {
    position: absolute;
    right: 3rem;
    top: 2rem;
    color: white;
    width: 6rem;
  }
  
  .right-h > .btn:hover {
    color: white;
    background-color: transparent;
    border: 4px solid white;
  }
  
  @media screen and (max-width: 768px) {
    .hero {
      flex-direction: column;
      overflow-x: hidden; /* Prevent horizontal scrolling */
    }
  
    .the-best-ad {
      margin-top: 30px;
      font-size: small;
      align-self: center;
      transform: scale(0.8);
    }
  
    .hero-text {
      font-size: xx-large;
      align-items: center;
      justify-content: center;
    }
  
    .hero-text > div:nth-of-type(3) {
      font-size: small;
      font-weight: 200;
      letter-spacing: 1px;
      text-align: center;
    }
  
    .hero-buttons {
      justify-content: center;
    }
  
    .figures > div > span:nth-of-type(1) {
      font-size: large;
    }
  
    .figures > div > span:nth-of-type(2) {
      font-size: small;
    }
  
    .right-h {
      position: relative;
      background: none;
    }
  
    .right-h > .btn {
      margin-right: 5rem;
    }
  }
  