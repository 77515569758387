.search-header{
    display: flex;
    gap: 5rem;
    font-weight: bold;
    font-size: 3rem;
    justify-content: center;
    color: rgb(254, 4, 204);
    text-transform: uppercase;
    font-style: italic;
}

.search-bar{
    display: flex;
    justify-content: center;
    align-items: center;
}

.search-bar>input{
    width: 35rem;
    height: 1.5rem;
    margin-top: 2rem;
    padding: 0.7rem;
    border-radius: 0.5rem;
    border: 1px solid gray;
}

.search-bar>button{
    margin-top: 2rem;
    margin-left: 0.6rem;
    border-radius: 0.5rem;
    width: 10rem;
    height: 3rem;
    color: white;
    cursor: pointer;
}

.exercise-category {
    display: flex;
    flex-wrap: wrap; 
    justify-content: center;
    margin-top: 4rem;
    gap: 3rem; 
}

.exercise {
    width: calc(12% - 1rem);
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.896);
    padding: 2rem;
    gap: 1rem;
    color: black;
    border: 1px solid rgba(49, 56, 29, 0.896);
    border-radius: 1rem;
    align-items: center;
    text-align: center;
    cursor: pointer;
}

.exercise:hover{
    background-color: rgba(49, 56, 29, 0.896);
    color: white;
    cursor: pointer;
}

.exercise > :nth-child(1) {
    width: 2rem;
    height: 2rem;
}

.exercise > :nth-child(2) {
    font-size: 1rem;
    font-weight: bold;
}

.learn-heading{
    display: flex;
    gap: 5rem;
    font-weight: bold;
    font-size: 3rem;
    justify-content: center;
    color: rgb(254, 4, 204);
    text-transform: uppercase;
    font-style: italic;
    margin-top: 10rem;
}

.learn{
    margin-top: 2rem;
    display: flex;
}

.left-learn{
    padding: 2rem;
    padding-top: 1.5rem;
    flex: 2;
    display: flex;
    gap: 2rem;
    line-height: 2rem;
    flex-direction: column;
}

.right-learn{
    flex: 1.5;
    position: relative;
    
}

.exercise-blur-1{
    right: 15%;
    width: 30rem;
    height: 15rem;
    filter: blur(200px);
    background: rgba(189, 213, 130, 0.896);
}

.exercise-blur-2{
left: 15%;
width: 30rem;
height: 15rem;
filter: blur(200px);
background:rgba(189, 213, 130, 0.896);
}

@media screen and (max-width:768px){
    .search-header{
        flex-direction: column;
        gap: 1rem;
        font-size: x-large;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
    }
    .search-bar{
        flex-direction: column;
    }
    .search-bar>input{
        width: 15rem;
        align-items: center;
        justify-content: center;
       
    }
    .search-bar>.btn{
        width: 10rem;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .exercise{
        flex-direction: column;
        width: 5rem;
    }
    .exercise-blur-1{
        right: 15%;
        width: 10rem;
        height: 15rem;
        filter: blur(200px);
        background: rgba(189, 213, 130, 0.896);
    }
    
    .exercise-blur-2{
    left: 15%;
    width: 10rem;
    height: 15rem;
    filter: blur(200px);
    background:rgba(189, 213, 130, 0.896);
    }

    .learn-heading{
        flex-direction: column;
        gap: 1rem;
        font-size: x-large;
        align-items: center;
        justify-content: center;
        margin-top: 6rem;
    }
    .learn{
        flex-direction: column;
    }
    .left-learn{
        font-size: small;
        text-align: center;
    }
}